@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

*{
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: 'Poppins', sans-serif;

}


a{
  text-decoration: none;
  color: inherit;
}

a:hover{
  /* text-decoration: underline; */

}

button{
  cursor: pointer;
}
.language-selector {
  width: 100px; 
}

.language-selector select {
    width: 100%; 
}