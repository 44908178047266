:root {
 --theme-color: #313f78;
 box-sizing: border-box;
}
.mainContainer {
 width: 85%;
 margin: auto;
}
.home_container {
 overflow-x: hidden;
}
.map {
 width: 100%;
}
.map iframe {
 border-radius: 20px;
}
.i4ewOd-pzNkMb-haAclf {
 display: none !important;
 visibility: hidden;
}
.gm-fullscreen-control {
 display: none !important;
}
.home_wrapper {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin: 0 auto;
 padding-left: 100px;
 padding-bottom: 40px;
 padding-top: 10px;
 border-bottom: 1px solid #00000026;
 background-image: url("/public/img/hero.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 height: 60vh;
 color: white;
 width: 100%;
 border-top: 2px solid white;
 overflow-x: hidden;
}

.home_button button {
 background-color: #336eca;
 border: none;
 border-radius: 3px;
 color: white;
 height: 4vh;
 width: fit-content;
 white-space: nowrap;
 font-weight: 700;
}

.home_wrapper h1 {
 font-size: clamp(0.5rem, 3rem, 3vw);
 line-height: 45px;
}
.home_wrapper p {
 font-size: 14px;
 width: 70%;
 line-height: 25px;
}

.home_content {
 width: 50%;
 display: flex;
 flex-direction: column;
 gap: 15px;
}

.home_image {
 width: 30%;
 position: relative;
}

.home_image .none_relative {
 width: 90%;
}

.relative_image {
 position: absolute;
 width: 80%;
 top: -10%;
 left: -50%;
 border: solid 10px white;
}

.home_count {
 display: flex;
 width: 90%;
 margin: 20px auto;
 justify-content: space-evenly;
 align-items: center;
 flex-wrap: wrap;
}

.home_count h1 {
 font-size: 30px;
 font-weight: 500;
}

.home_count p {
 font-size: 14px;
}
.employe_count {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 border: 4px solid var(--theme-color);
 height: 120px;
 width: 120px;
 border-radius: 50%;
 color: var(--theme-color) !important;
}
.employe_count p {
 text-align: center;
}

.service_container {
 background-color: transparent;
 /* background-image: linear-gradient(180deg,#AFC6E6 0%,#E9E6EF 100%); */
 background-image: url("/public/img/gradient.png");
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
 height: 100%;
 margin-top: 130px;
 padding: 1rem;
 margin-top: 1rem;
}
.service_wrapper {
 margin: 0 auto;

 height: 100%;
 padding: 20px;
 width: 90%;
 margin-top: 50px;
}

.service_wrapper h1 {
 font-size: 20px;
 text-align: center;
}

.service_wrapper h1 span {
 color: var(--theme-color);
}

.service_wrapper_div {
 width: 90%;
 margin: 15px auto;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10px;
 flex-wrap: wrap;
}
.first_service {
 width: 28%;
 border: solid 1px #00000026;
 padding: 20px;
 border-radius: 10px;
 height: 250px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 10px;
 background-color: white;
}
.first_service a {
 font-size: 14px;
 color: #336eca;
 font-weight: 500;
}

.first_service h3 {
 font-size: 14px;
}

.first_service p {
 font-size: 12px;
}

.footer_container {
 /* height: 27vh; */
 /* margin-top: 40px; */
 background-color: var(--theme-color);
 padding: 20px;
 color: white;
}

.footer_wrapper {
 display: flex;
 justify-content: space-around;
 /* padding: 20px; */
}

.social_icons {
 display: flex;
 gap: 20px;
 margin-top: 10px;
 justify-content: center !important;
 align-items: center;
}

.company_logo {
 width: 100%;
 margin-top: 50px;
 display: flex;
 justify-content: flex-end;
}

.company_logo img {
 width: 50%;
}

.right_text p {
 text-align: center;
 font-size: 10px;
}

.social_icons svg {
 cursor: pointer;
}

.navbar_container {
 background-color: white !important;
}

.navbar_wrapper {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 10px;
 width: 90%;
 margin: auto;
}
.empty_space {
 width: 10%;
}
.navbar_logo {
 width: 15%;
}

.navbar_logo img {
 width: 100%;
}

.menu_toggle {
 display: flex;
 align-items: center;
 gap: 10px;
 cursor: pointer;
 color: #303f7a;
}

.contact_container {
 /* height: 90vh; */
 padding-bottom: 40px;
 display: flex;
 justify-content: center;

 background-color: #f2f2f2;
}
.contact_wrapper {
 width: 90%;
 margin-top: 50px;
}

.contact_wrapper h1 {
 text-align: center;
 font-size: 20px;
}

.contact_wrapper .heading_contact {
 text-align: center;
 font-size: 14px;
}
.form_video {
 width: 100%;
}
.form_wrapper {
 width: 40%;
 margin-top: 30px;
 display: flex;
 flex-direction: column;
 gap: 20px;
}
.name_email {
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.form_wrapper input {
 height: 20px;
 width: 100%;
 margin-top: 5px;
 border: none;
 border-radius: 5px;
}

.form_wrapper input[type="text"] {
 padding: 5px;
 font-size: 10px;
 font-family: "Poppins", sans-serif;
}
.form_wrapper input:focus {
 outline: none;
}
.form_wrapper textarea {
 height: 240px;
 width: 100%;
 margin-top: 5px;
 border: none;
 border-radius: 5px;
 padding: 10px;
 font-family: "Poppins", sans-serif;
 font-size: 10px;
}
.form_video {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 80px;
}
.name_div p {
 color: #6c757d;
 font-size: 14px;
}

.video_div {
 width: 40%;
}

.video_div video {
 width: 100%;
}

.video_wrapper {
 height: 60vh;
 margin-top: 40px;
 border-radius: 20px;
 overflow: hidden;
 transition: ease-in 0.5s;
 box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05),
  0 1.5rem 2.2rem rgba(0, 0, 0, 0.1) !important;
}

.video_wrapper:hover {
 transform: scale(1.04);
}
.form_wrapper textarea:focus {
 outline: none;
}

.get_in_touch {
 width: 10%;
 margin-top: 0;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
}
.company_logo {
 width: 100%;
 margin-top: 10px;
 display: flex;
 justify-content: center;
}
.get_in_touch h4 {
 text-align: right;
}
.timeline_container {
 display: flex;
 justify-content: center;
 align-items: center;
}

.timeline_wrapper {
 width: 60%;
 margin: 10px auto;
 display: flex;
 flex-direction: column;
 align-items: center;
 align-items: center;
 gap: 10px;
}

.timeline_wrapper h1 {
 text-align: center;
 font-size: 22px;
 width: 70%;
}

.timeline_wrapper p {
 text-align: center;
 font-size: 18px;
}

.together_container {
 background-color: transparent;
 background-image: linear-gradient(230deg, #afc6e6 0%, #e9e6ef 86%);
 padding: 40px;
}

.together_wrapper {
 width: 90%;
 margin: 0 auto;
 display: flex;
 justify-content: space-around;
 align-items: center;
 flex-wrap: wrap;
 gap: 20px;
}

.together_content {
 width: 40%;
}

.together_content h1 {
 font-size: 25px;
}

.together_content p {
 font-size: 14px;
}
.quick_links {
}
.quick_links p {
 cursor: pointer;
 font-size: 12px;
}

.together_image {
 width: 30%;
}

.together_image img {
 width: 100%;
}
.quick_links h4 {
 font-size: 14px;
}
.faq_wrapper {
 width: 85%;
 margin: 30px auto;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 gap: 40px;
}

.faq_wrapper h1 {
 text-align: center;
 font-size: 20px;
 width: 50%;
}

.faq_div_wrapper {
 background-color: #f5f5f5;
 height: 100%;
 width: 100%;
 border-radius: 4px;
 padding: 30px;
 display: flex;
 flex-direction: column;
 gap: 30px;
}

.question_div {
 border-bottom: solid 0.1px;
 padding-bottom: 20px;
 transition: all 0.5s ease-in;
}

.question_data {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
}

.question_div p {
 font-size: 14px;
 font-weight: 500;
}

.question_plus {
 background-color: #5f6567;
 border-radius: 50%;
 width: 25px;
 height: 25px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.answer_div {
 transition: max-height 0.3s ease-out;
}
.answer_div p {
 font-size: 12px;
 font-weight: 100;
}

.policy_text {
 width: 85%;
 margin: 32px auto;
 font-size: 14px;
}

.policy_text h5 {
 font-size: 20px;
 font-size: 14px;
}
.policy_text h4 {
 font-size: 14px;
 font-weight: 400;
}
.policy_text p {
 font-size: 12px;
}
.tnc_wraper {
 width: 85%;
 margin: 30px auto;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.tnc_wraper h1 {
 text-align: center;
 font-size: 16px;
 width: 50%;
}
.tnc_wraper p {
 text-align: center;
 font-size: 14px;
}

.contact_wrapper_page {
 display: flex;
 flex-direction: column;
 gap: 50px;
 width: 90%;
 margin: 20px auto;
 justify-content: center;
}

.contact_wrapper_page h1 {
 font-size: 20px;
 line-height: 30px;
 text-align: center;
}

.contact_wrapper_page h1 span {
 color: var(--theme-color);
}

.contact_details_div {
 display: flex;
 justify-content: space-around;
 align-items: center;
 flex-wrap: wrap;
 width: 90%;
 margin: auto;
}

.contact_call_us {
 width: 24%;
 border: solid;
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 8px;
 padding: 20px;
 border: 0px solid rgba(0, 0, 0, 0.125);
 background-clip: border-box;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 border-radius: 5px;
 cursor: pointer;
 transition: ease-in 0.5s;
 background-color: white;
}

.contact_call_us:hover {
 transform: scale(1.04);
}

.contact_call_us h5 {
 font-size: 16px;
 margin-top: 5px;
 font-weight: 500;
}
.contact_call_us p {
 font-size: 14px;
}
.contact_call_us button {
 background-color: var(--theme-color);
 border: none;
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
 gap: 5px;
 width: 70%;
 border-radius: 4px;
 height: 30px;
}
.send_button {
 display: flex;
 justify-content: flex-end;
}
.send_button button {
 background-color: var(--theme-color);
 border: none;
 color: white;
 border-radius: 3px;
 width: auto;
 height: 25px;
 font-size: 16px;
 margin: auto;
}
.menu_toggle {
 width: 45%;
}
.menu_link {
 display: flex;
 width: 100%;
 justify-content: space-between;
}
.menu_link a {
 cursor: pointer;
 font-weight: 500;
 color: black;
}

.menu_link p {
  cursor: pointer;
  font-weight: 500;
  color: black;
 }

.menu_link a:hover {
 color: var(--theme-color);
}

.menu_toggle svg {
 display: none;
}

.section_container {
 margin-top: 40px;
 height: fit-content;
 margin-bottom: 8rem;
}
.section_wrapper {
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap: 30px;
 width: 80%;
 margin: 0 auto;
}

.section_img {
 width: 38%;
}

.section_img img {
 width: 100%;
 height: 100%;
}

.section_content {
 width: 50%;
}
.section_content_wrapper {
 display: flex;
 flex-direction: column;
 gap: 5px;
}

.section_content_wrapper h1 {
 font-size: 20px;
}

.section_content_wrapper p {
 font-size: 14px;
}
.section_content_wrapper button {
 background-color: var(--theme-color);
 color: white;
 border: none;
 width: fit-content;
 height: 4vh;
 border-radius: 3px;
 font-size: 12px;
}
.section_container {
 height: fit-content;
}

.third_img_wrapper {
 width: 100%;
 position: relative;
 height: 100%;
}

.third_img_wrapper img {
 width: 100%;
}

.service_icons {
 display: flex;
 justify-content: space-between;
 align-items: center;
 position: absolute;
 bottom: -15%;
 width: 100%;
}
.service_first_div {
 width: 20% !important;
}
.service_first_div img {
 width: 45% !important;
}

.service_first_div {
 display: flex;
 flex-direction: column;
 gap: 10px;
 justify-content: center;
 align-items: center;
}
.service_first_div p {
 font-size: 12px;
 text-align: center;
 font-weight: 500;
}
.sapBanner {
 width: 100%;
 border-top: 2px solid white;
}
.sapBanner img {
 width: 100%;
 position: relative;
 background-position: center;
 background-repeat: no-repeat;
}
.sapContent {
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-top: 2rem;
}
.sapContent p {
 width: 99%;
 margin: auto;
}

.sapKeyContent {
 margin: auto;
 margin-top: 1rem;
}
.sapKeyContent h3 {
 font-size: 20px;
}
.implsapLeft p {
 width: 99%;
 margin: auto;
}

.keyitem li {
 font-size: 14px;
 font-weight: 400;
 margin-bottom: 0.8rem;
 width: 90%;
}

.keyitem li span {
 font-size: 16px;
 font-weight: 500;
}
.sapContnetLeft {
 width: 50%;
}
.sapContentRight {
 width: 50%;
 display: flex;
 align-items: center;
 justify-content: flex-end;
}
.sapContentRight img {
 width: 73%;
}

.whysapContent p {
 width: 99%;
 margin: auto;
}
.sapContnetLeft h3 {
 font-size: 20px;
 font-weight: bold;
 margin-bottom: 0.6rem;
}
.sapItem li {
 margin-bottom: 0.5rem;
}

.sapContnetLeft p {
 font-size: 14px;
 line-height: 25px;
}
.keyitem {
 width: 100%;
}
.implSap {
 margin-top: 2rem;
 margin-bottom: 2rem;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.implsapLeft h3 {
 font-size: 20px;
 margin-bottom: 1rem;
}
.implsapLeft {
 width: 50%;
}
.implRight {
 width: 50%;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 margin-top: 2rem;
}
.implRight img {
 width: 73%;
}
.sap {
 width: 100%;
}
.implSap li {
 margin-bottom: 0.6rem;
 font-size: 14px;
 font-weight: 400;
}
.implSap p {
 font-size: 14px;
}
.whysapContent {
 margin: auto;
 display: flex;
 flex-direction: column;
 gap: 1rem;
}
.whysapContent h1 {
 font-size: 20px;
 margin-bottom: 0.6rem;
}

.whysapContent p,
.whysapContent li {
 font-size: 14px;
 font-weight: 400;
}
.whysapcontainer {
 margin: auto;
 display: flex;
 flex-direction: column;
 gap: 2rem;
 margin-top: 1rem;
 margin-bottom: 1rem;
}
.whysapcontainer h1 {
 font-size: 20px;
 margin-bottom: 0.6rem;
}
.whysapcontainer p {
 font-size: 14px;
 width: 99%;
 margin: auto;
}
.cookie-consent-backdrop {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 1000;
}
.cookie-consent-banner {
 position: fixed;
 bottom: 0;
 left: auto;
 z-index: 2147483645;
 box-sizing: border-box;
 width: 60%;
 padding:3rem 1rem ;
 border-radius: 10px;
 background-color: #336eca;
 color: white;
 display: flex;
 justify-content: space-around;
 align-items: center;
}
.cookie-consent-banner__description {
 font-weight: normal;
 font-size: 14px;
 line-height: 24px;
 width: 50%;
 text-indent: 0px;
 text-justify: center;
}
.cookie-button-wrapper {
 display: flex;
 flex-direction: column;
 gap: 10px;
 padding:0 1rem ;
}
.cookie-consent-button {
 box-sizing: border-box;
 align-items: center;
 min-width: 164px;
 padding: 11px 13px;
 border-radius: 5px;
 border: 1px solid white;
 background-color: transparent;
 color: #fff;
 text-decoration: none;
 text-align: center;
 font-weight: normal;
 font-size: 16px;
 line-height: 20px;
 position: relative;
 margin: 0 10px;
}
.cookie-banner-svg img{
  width: 100px;
  height: 100px;
 }
.active_blue{
  color: #336eca!important;
}
@media (max-width: 800px) {
 body {
  overflow-x: hidden;
 }
 .section_container {
  margin-bottom: 1rem;
 }
 .implRight img {
  width: 100%;
 }
 .sapContentRight img {
  width: 100% !important;
 }
 .sapContentRight {
  width: 100%;
 }
 .home_content h1 {
  font-size: 20px;
 }
 .third_img_wrapper {
  height: fit-content;
 }
 .service_icons {
  position: static;
  flex-direction: column;
  height: fit-content;
 }
 .sapItem {
  padding-left: 1rem;
 }
 .service_first_div {
  height: 150px;
  border: 1px solid #bcbcbc;
  width: 100% !important;
 }
 .service_first_div img {
  width: 20% !important;
 }
 .service_first_div p {
  font-size: 12px;
 }
 .section_wrapper {
  flex-direction: column;
 }
 .section_img {
  width: 100%;
 }
 .section_content_wrapper {
  width: 100%;
  height: fit-content;
 }
 .section_content {
  width: 100%;
 }
 .company_logo img {
  width: 80%;
 }
 .timeline_wrapper h1 {
  font-size: 20px;
 }

 .home_container {
  /* height: 130vh; */
  margin-top: 0px;
  height: fit-content;
 }
 .first_service {
  width: 100%;
 }
 .home_image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .home_content {
  width: 80%;
  gap: 5px;
 }

 .home_content h1 {
  line-height: 35px;
 }
 .home_content p {
  width: 100%;
  font-size: 12px;
 }
 .home_button button {
  width: fit-content;
 }
 .first_service {
  width: 100%;
  height: fit-content;
 }
 .together_image {
  width: 100%;
 }

 .service_wrapper {
  margin-top: 0px;
  padding: 1px;
  padding-top: 10px;
  height: 100%;
 }

 .footer_wrapper {
  flex-direction: column;
  gap: 20px;
 }
 .home_wrapper {
  justify-content: center;
  padding: 1rem;
  margin: 0;
 }
 .home_wrapper h1 {
  font-size: clamp(1.7rem, 1rem, 1vw);
 }
 .footer_container {
  /* height: 80vh; */
  margin-top: 0;
 }

 .get_in_touch {
  width: 100%;
 }
 .company_logo {
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
 }
 .right_text {
  margin-top: 20px;
 }
 .company_logo {
  width: 45%;
  margin-top: 20px;
 }
 .form_video {
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin: 0 auto;
 }
 .form_wrapper textarea {
  width: 97%;
 }
 .form_wrapper {
  width: 100%;
  gap: 10px;
 }
 .video_div {
  width: 100%;
 }
 .video_wrapper {
  height: 40vh;
  margin-top: 10px;
 }
 .contact_call_us {
  width: 90%;
  margin-top: 20px;
  align-items: center;
  text-align: center;
 }
 .contact_details_div {
  padding-bottom: 30px;
 }
 .faq_wrapper {
  width: 100%;
  margin: 0;
  gap: 15px;
 }
 .faq_wrapper h1 {
  width: 80%;
 }
 .question_div p {
  width: 78%;
 }
 .faq_div_wrapper {
  width: 90%;
 }
 .question_div {
  padding-bottom: 10px;
 }
 .navbar_logo {
  width: 45%;
 }
 .menu_link {
  display: none;
 }
 .menu_toggle {
  width: 10%;
 }
 .contact_wrapper_page {
  gap: 0px;
 }

 .full_screen_menu {
  position: fixed;
  width: 85%;
  height: auto;
  top: 0;
  left: 0;
  background-color: white;
  padding: 30px;
  z-index: 9;
 }
 .menu_link_mobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  /* color: white; */
  margin-top: 10px;
 }
 .menu_link_mobile a {
  font-size: 20px;
 }
 .menu_link_mobile p {
  font-size: 20px;
 }
 .menu_toggle svg {
  display: block;
 }
 .timeline_wrapper {
  width: 100%;
 }
 .together_container {
  padding: 20px;
 }
 .together_content {
  width: 100%;
 }

 .contact_wrapper {
  margin-top: 20px;
 }

 .home_count {
  gap: 10px 0px;
 }
 .get_in_touch h4 {
  text-align: left;
 }
 .social_icons {
  justify-content: flex-start;
 }
 .relative_image {
  display: none;
 }
 .sapContent {
  width: 90%;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  margin-top: 1rem;
 }
 .sapContnetLeft {
  width: 100%;
 }
 .sapContnetRight {
  width: 100%;
  justify-content: center;
  display: block;
 }

 .sapKeyContent {
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 90%;
 }
 .keyitem li {
  width: 90%;
 }

 .implSap {
  width: 90%;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  gap: 0.5rem;
 }
 p {
  width: 100%;
 }
 .implItem {
  width: 90%;
  padding-left: 0.5rem;
  text-align: justify;
 }

 .implItem li {
  margin: 0;
  padding: 0;
 }
 .implsapLeft {
  width: 100%;
 }
 .implRight {
  width: 100%;
 }
 .implRight img {
  width: 100%;
 }
 .whysapContent {
  width: 90%;
  display: block;
 }
 .whysapContent img {
  width: 100%;
 }
 .map .i4ewOd-pzNkMb-haAclf {
  display: none;
 }
 .sapItem {
  width: 90%;
  padding-left: 0.5rem;
  text-align: justify;
 }
 .sapItem li {
  text-align: justify;
 }

 .sapBanner {
  width: 100%;
 }
 .sap h1 {
  font-size: 16px;
 }

 .sap li {
  font-size: 12px;
 }
 .sap li span {
  font-size: 14px;
 }
 .keyitem {
  padding: 0;
  width: 100%;
  padding-left: 0.5rem;
 }
 .sap p {
  font-size: 12px;
 }
 .sap h3 {
  font-size: 16px !important;
 }
 .sap {
  width: 100%;
 }
 .sapContentRight img {
  width: 100%;
  display: block;
 }
 .implRight img {
  width: 100%;
  display: block;
 }
 .whysapcontainer {
  width: 90%;
  gap: 0.5rem;
 }
 .whysapcontainer h3 {
  margin-top: 0;
 }
 .implRight {
  margin-top: 0;
 }
 .whysap {
  padding: 0;
 }
.cookie-consent-banner {
 width: 90%;
 padding:1rem 0;
 flex-direction: column;
}
.cookie-button-wrapper {
 display: inline;
 gap: 10px;
 padding:0 1rem ;
}
.cookie-consent-banner__description {
 width: 90%;
 margin: 1rem;
}
.cookie-consent-button {
 font-size: 12px;
 line-height: 10px;
 min-width: 100px;
 padding: 11px 13px;
}
}

@media (min-width: 800px) and (max-width: 1024px) {
 .menu_toggle {
  width: 60%;
  font-size: 12px;
 }
}
/* .active_blue{
  color: var(--theme-color) !important;
} */
